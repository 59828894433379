.bg {
  background-color: #f5f8ff;
  position: relative;
}
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.patternLeft {
  position: absolute;
  top: -13%;
  left: 0;
}
.patternRight {
  position: absolute;
  bottom: -20%;
  right: 0;
}

.patternMobile {
  display: none;
}

.getStarted {
  padding: 4.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__explore {
    color: var(--vobb-primary-70);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 18px;
    letter-spacing: -0.25px;
    margin-bottom: 1rem;
  }
  &__ttl {
    color: var(--neutral-100);
    font-weight: 700;
    font-size: 38px;
    line-height: 44.85px;
    letter-spacing: -0.5px;
    margin-bottom: 1.5rem;
  }
  &__txt {
    line-height: 25.6px;
    margin-bottom: 1.2rem;
  }
  &__link {
    padding: 12px 24px;
    border-radius: 6px;
    background-color: var(--vobb-primary-70);
    color: var(--white);
  }
}
@media only screen and (max-width: 1024px) {
  .patternRight {
    // display: none;
    right: 1rem;
    width: 94px;
    bottom: -15%;
  }

  .patternLeft {
    top: -12%;
    left: 1rem;
    width: 94px;
  }

  .patternMobile {
    display: block;
    position: absolute;
    top: -8%;
    left: 1rem;
  }

  .getStarted {
    padding: 5rem 0;
    &__ttl {
      font-size: 24px;
      margin-bottom: 1rem;
    }
    &__explore {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
