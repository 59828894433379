*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --vobb-primary-70: #4a22eb;
  --atlas-primary-70: #fa2727;
  --white: #fff;
  --error: #e74444;

  /* Vobb Colors */
  --vobb-primary-70: #4a22eb;

  /* Vobb Colors */
  --vobb-sec-0: #f5f8ff;

  /* Neutrals */
  --neutral-100: #101323;
  --neutral-90: #1d2939;
  --neutral-80: #344054;
  --neutral-70: #475467;
  --neutral-60: #667085;
  --neutral-50: #98a2b3;
  --neutral-40: #d0d5dd;
  --neutral-30: #eaecf0;
  --neutral-20: #f2f4f7;
  --neutral-10: #f9fafb;
  --neutral-0: #fcfcfd;

  --max-width: 1160px
}

html body {
  margin: 0;
  color: var(--neutral-60);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;
  height: 100%;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  /* line-height: 25.6px; */
}
body a {
  color: inherit;
  text-decoration: none !important;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: 0.3s;
}
/* width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--vobb-primary-70);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--vobb-primary-70);
}

button,
input,
textarea {
  font-family: "Inter", sans-serif;
  outline: none !important;
}

button,
[role="button"] {
  cursor: pointer;
}

input::placeholder {
  color: rgba(193, 199, 208, 1);
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f3f3f3 inset !important;
  box-shadow: 0 0 0 30px #f3f3f3 inset !important;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: var(--max-width);
  margin: auto;
}

@media screen and (max-width: 800px) {
  html body {
    font-size: 14px;
  }
}

/* notion blog styles */
.nbr-image img {
  width: 100%;
  height: auto;
}
.nbr-block-callout {
  display: flex;
  gap: 1rem;
  background-color: var(--neutral-10);
  padding: 0.5rem;
}
