.heading {
  margin: 0;
  &--small {
    color: var(--vobb-primary-70);
    font-weight: 600;
    line-height: 28px;
    font-size: 18px;
  }
  &--large {
    max-width: 510px;
    font-size: 39px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
  }
}

@media only screen and (max-width: 800px) {
  .heading {
    &--small {
      font-size: 15px;
    }
    &--large {
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
  }
}
