.heroBg {
  background-image: url(../../../assets/vectors/illustrations/heroPattern.svg);
  padding-top: 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.hero {
  color: var(--white);
}
.header {
  text-align: center;
  padding: 4rem 0 15rem;

  &__ttl {
    font-size: 58px;
    font-weight: 600;
    max-width: 700px;
    margin: auto;
    margin-bottom: 1.5rem;
    line-height: 1.3;
    position: relative;

    .svgStroke {
      position: absolute;
      top: 50%;
      right: -16.5%;
    }
  }
  &__txt {
    max-width: 550px;
    margin: auto;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  &__btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: auto;
    width: fit-content;

    a {
      padding: 14px 24px;
      border-radius: 6px;
    }

    .accessLink {
      border-radius: 6px;
      background-color: var(--vobb-primary-70);
      color: var(--white);
    }
    a:last-of-type {
      color: var(--atlas-primary-70);
      font-weight: 600;
      background-color: var(--white);
    }
  }
}
.imgSec {
  margin-top: -13rem;
  svg {
    width: 105%;
    margin-left: -2.5%;
    height: auto;
  }

  &__desktop {
    display: block;
  }

  &__mobile {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .heroBg {
    background-image: url(../../../assets/vectors/illustrations/heroMobilePattern.svg);
    background-position: top right;
    background-size: cover;
  }
  .header {
    &__ttl {
      font-size: 31px;
      font-weight: 700;
      line-height: 37px;
      letter-spacing: -0.5px;
      text-align: center;
      max-width: 400px;
      .svgStroke {
        right: 0;
        width: 150px;
        top: 30%;
      }
    }
    &__btns {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
  }
  .imgSec {
    margin-top: -12rem;

    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }
    svg {
      width: 110%;
      margin-left: -5%;
    }
  }
}
