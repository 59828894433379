.globeWrap {
  position: relative;
}
.location {
  z-index: 1;

  &__toolTip {
    position: absolute;
    top: -1000%;
    left: -600%;
    // max-width: 200px;
    padding: 1rem;
    font-size: 13px;
    border-radius: 9px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    min-width: 120px;
    box-shadow: 1px 1px 6px 2px rgb(0 0 0 / 10%);

    h2 {
      color: var(--neutral-100);
      font-size: 13px;
      white-space: nowrap;
      margin: 0.5rem 0;
    }
    p {
      margin: 0;
    }
  }

  &__circle {
    width: 13.54px;
    height: 13.54px;
    background: var(--vobb-primary-70);
    padding: 6px;
    gap: 0px;
    border: 0.46px solid var(--vobb-primary-70);
    border-radius: 50%;
    outline: 1px solid var(--vobb-primary-70);
    outline-offset: 6.77px;
  }
}
.map {
  width: 100%;
  height: 100%;
}
