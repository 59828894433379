.progressWrap {
  height: 4px;
  position: relative;
  display: none;
  &__inner {
    height: 100%;
    transition: width 0.5s ease;
  }

  @media only screen and (max-width: 800px) {
    display: block;
  }
}
