.bg {
  margin-top: -10rem;
}
.contentBtm {
  display: flex;
  justify-content: space-between;
  gap: 69px;
  &__bigHeader {
    max-width: 596px;
    margin-top: 1rem;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    font-size: 36px;
    margin-bottom: 1rem;
  }
  &__paragraph {
    font-weight: 400;
    letter-spacing: -0.25px;
    text-align: left;
    max-width: 456px;
    margin-bottom: 1.8rem;
    line-height: 1.5;
  }
  &__checks {
    &--item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 8px;
      span {
        line-height: 1.5;
        letter-spacing: -0.25px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    margin-top: -13rem;
    padding-bottom: 3rem;
  }
  .contentBtm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
    &__checks {
      &--item {
        align-items: flex-start;
      }
    }
    &__right {
      max-width: 100%;
      height: auto;
      margin-top: 2rem;
    }
  }
}
