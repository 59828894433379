.bg {
  padding: 5rem 0;
  background-image: url(../../../assets/vectors/illustrations/education/hero-bg.svg);
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
}
.contentTop {
  max-width: 508px;
  &__bigHeader {
    font-size: 35px;
    line-height: 45px;
    letter-spacing: -0.5px;
    margin: 1rem 0;
    text-align: left;
    color: var(--neutral-100);
  }
  &__paragraph {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin: 0;
  }
}

.contentBtm {
  display: grid;
  margin-top: 3rem;
  grid-template-columns: 1fr 2fr;
  position: relative;

  &__left {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    &--item {
      max-width: 387px;

      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.25px;
        margin: 0;
      }
      .desc {
        font-size: 31px;
        font-weight: 700;
        line-height: 37.2px;
        letter-spacing: -0.5px;
        color: var(--neutral-100);
        margin: 1rem 0;
      }
      .paragraph {
        font-weight: 400;
        line-height: 25.6px;
        letter-spacing: -0.25px;
        margin: 0;
      }
    }
  }
  &__right {
    text-align: right;
    img {
      max-width: 692px;
      width: 100%;
      margin-left: auto;
      display: block;
      height: auto;
    }
  }
}
.contentLast {
  display: none;
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
  }
  .contentBtm {
    grid-template-columns: 1fr;

    &__left {
      margin-bottom: 2rem;
      gap: 2rem;
      padding-top: 0;

      &--item {
        max-width: unset;

        .title {
          font-size: 14px;
        }
        .desc {
          font-size: 18px;
          margin-top: 0px;
        }
      }
    }
    &__right {
      img {
        max-width: unset;
      }
    }
  }
}
