.portalWrap {
  &__productItem {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
    width: 273px;

    &:hover {
      background-color: #f5f8ff;
    }

    &--content {
      max-width: 173px;
      p:first-of-type {
        margin-top: 2px;
        margin-bottom: 14px;
        font-size: 14px;
        color: var(--neutral-100);
        font-weight: 600;
        white-space: nowrap;
      }
      p:last-of-type {
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: -0.25px;
      }
    }
    @media only screen and (max-width: 1024px) {
      padding: 1rem 0.5rem;
      width: 100%;
      &--content {
        max-width: 100%;
        p {
          font-size: 13.5px;
        }
      }
    }
  }
}
