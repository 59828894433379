.bg {
  padding: 5rem 0;
}
.contentTop {
  &__bigHeader {
    max-width: 550px;
    margin-top: 12px;
    margin-bottom: 1.5rem;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    line-height: 1.35;
    font-size: 37px;
  }
  &__paragraph {
    max-width: 589px;
    line-height: 1.6;
    letter-spacing: -0.25px;
    margin-bottom: 6px;
  }
}

.contentBtm {
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 1rem;

  &__item {
    max-width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;

    > svg {
      height: 32px;
      width: 32px;
      margin-bottom: 1.5rem;
    }

    .title {
      color: var(--neutral-100);
      font-weight: 600;
    }

    p:last-of-type {
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;

      a {
        color: var(--vobb-primary-70);
      }
    }
  }
}
.contentLast {
  max-width: 100%;
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
  }
  .image {
    margin-top: 0;
  }
}
