.heroBg {
  padding: 5rem 0 2rem;
  position: relative;
}
.hero {
  &__top {
    display: flex;
    padding-top: 4rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    max-width: 1050px;
    align-items: flex-end;

    &--right {
      max-width: 389px;
      padding-left: 1rem;
      margin-bottom: 1rem;

      font-size: 15px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: -0.2px;
      text-align: left;
    }
  }

  &__bigHeader {
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -0.5px;
    height: 151px;
    margin-top: 1rem;
    width: 562px;
    max-width: 100%;
    line-height: 1.3;

    strong {
      color: #a491f5;
      font-weight: 700;
    }
  }
}
.imgSec {
  display: flex;
  overflow: auto;
  gap: 1.4rem;
  padding: 0 1rem;
  margin-left: calc((100vw - (1160px)) / 2);

  img {
    max-width: 650px;
    height: 100%;
    width: 92%;
  }
}

@media screen and (max-width: 1128px) {
  .imgSec {
    margin-left: 0;
  }
}

@media only screen and (max-width: 800px) {
  .heroBg {
    padding-bottom: 0;
  }
  .hero {
    &__top {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;

      &--right {
        max-width: 100%;
        padding-left: 0;
        margin-bottom: 2.5rem;
      }
    }
    &__bigHeader {
      font-size: 25px;
      font-weight: 700;
      letter-spacing: -0.25px;
      text-align: left;
      height: auto;
      width: 100%;
      line-height: 1.5;
      margin-bottom: 1rem;
      margin-top: 10px;
    }
    &__btm {
      margin-top: 2rem;
      &--pic2 {
        display: none;
      }
    }
  }
  .imgSec {
    gap: 1rem;
    margin-bottom: 2rem;
  }
}
