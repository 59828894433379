.bg {
  padding-top: 100px;
}

.content {
  h2 {
    font-size: 1.5rem;
    color: var(--neutral-100);
  }
  .goBack {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    color: var(--vobb-primary-70);
    font-weight: 500;
    font-size: 14px;

    svg {
      width: 20px;
      height: 20px;
      transform: rotate(180deg);

      path {
        fill: var(--vobb-primary-70);
      }
    }
  }

  &__top {
    .banner {
      width: 100%;
    }
    .headline {
      text-transform: uppercase;
      font-size: 1.5rem;
      letter-spacing: 0.1rem;
      color: var(--vobb-primary-70);
    }
    .heading {
      margin: 0;
      color: var(--neutral-100);
      font-size: 45px;
      text-transform: capitalize;
      line-height: 60px;
    }
    .body {
      text-align: justify;
      margin: 2rem 0;
    }

    .categories {
      span {
        margin-right: 1rem;
        text-transform: capitalize;
        border: 1px solid var(--neutral-60);
        padding: 0.5rem;
        border-radius: 6px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  &__btm {
    margin-top: 6rem;
    h2 {
      font-size: 2.5rem;
    }
    .articles {
      padding: 3rem 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      padding-top: 20px;
      justify-content: space-between;
      grid-gap: 70px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .content {
    &__top {
      .banner {
        height: 300px;
      }
      .headline {
        font-size: 1rem;
      }
      .heading {
        font-size: 25px;
        line-height: 35px;
      }
    }
    &__btm {
      h2 {
        font-size: 25px;
      }
    }
  }
}
