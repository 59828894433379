.bg {
  padding: 3.5rem 0;
  background-color: #f5f8ff;
}
.contentTop {
  &__bigHeader {
    max-width: 712px;
    font-size: 37px;
    margin-top: 1rem;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    margin-bottom: 1rem;
  }
  &__paragraph {
    max-width: 589px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
  }
}

.contentBtm {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
  margin-top: 3rem;

  &__item {
    max-width: 285px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .image {
      svg {
        height: 32px;
        width: 32px;
      }
    }
    .title {
      color: var(--neutral-100);
      margin: 0;
      padding: 0;
      font-weight: 600;
      margin: 1.7rem 0 6px;
    }

    p {
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;
      margin: 0;

      a,
      span {
        color: var(--vobb-primary-70);
        font-weight: 600;
      }
    }
  }
}
.contentLast {
  max-width: 100%;
}

@media only screen and (max-width: 800px) {
  .bg {
   padding-bottom: 4rem;
   padding-top: 2rem;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
  }

  .contentBtm {
    grid-template-columns: auto auto;
    gap: 1rem;

    &__item {
      max-width: 100%;
      margin-bottom: 2rem;
    }
  }
}
