.bg {
  background-color: #f5f8ff;
  padding: 5rem 0;
}

.content {
  &__heading {
    max-width: 660px;
    margin: 0.8rem 0 1.5rem;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
  }
  &__paragraph {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    max-width: 480px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0 4rem;
    background-color: white;
  }
  .content__heading {
    font-size: 25px;
    line-height: 31px;
    letter-spacing: -0.25px;
  }
  .content {
    &__paragraphWrap {
      margin-bottom: 3rem;
    }
  }
  .content__top {
    // display: none;
  }
}
