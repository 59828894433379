.bg {
  padding: 4rem 0;
}

.contentTop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  position: relative;
  align-items: center;

  &__left {
    a {
      background-color: var(--vobb-primary-70);
      padding: 12px 24px;
      text-align: center;
      color: var(--white);
      border-radius: 6px;
      font-size: 15px;
      display: block;
      width: fit-content;
    }
  }
  &__bigHeader {
    max-width: 466px;
    margin-top: 1rem;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    font-size: 36px;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  &__paragraph {
    max-width: 508px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 14px;
    margin-bottom: 1.5rem;

    strong {
      color: var(--vobb-primary-70);
    }
  }
  &__right {
    position: relative;
    height: 584px;
    width: 610px;
    overflow: hidden;

    .stanford,
    .liverpool,
    .washington,
    .wellington,
    .murdoch,
    .murdoch2,
    .coventry,
    .minnesota {
      position: absolute;
      z-index: -1;
    }
    .stanford {
      bottom: 0;
      left: 0;
      animation: bounce 4s infinite alternate ease-in-out;
      animation-delay: 0.5s;
    }

    .liverpool {
      bottom: 38%;
      right: 0;
      animation: bounce 4s infinite alternate ease-in-out;
      animation-delay: 1s;
    }

    .washington {
      bottom: 40%;
      left: 38%;
      animation: bounce 4s infinite alternate ease-in-out;
      animation-delay: 0.5s;
    }

    .wellington {
      bottom: 40%;
      left: 0;
      animation: bounce 4s infinite alternate ease-in-out;
    }

    .murdoch {
      top: 60%;
      right: 20%;
      animation: bounce 4s infinite alternate ease-in-out;
      animation-delay: 0.3s;
    }

    .coventry {
      bottom: 20%;
      left: 20%;
      animation: bounce 4s infinite alternate ease-in-out;
      animation-delay: 0.7s;
    }

    .minnesota {
      bottom: 0;
      right: 0;
      animation: bounce 4s infinite alternate ease-in-out;
      animation-delay: 1s;
    }
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}

@media only screen and (max-width: 800px) {
  .contentTop {
    grid-template-columns: 1fr;
    gap: 2rem;

    &__left {
      align-items: stretch;
    }
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
      margin-top: 12px;
    }
    &__paragraph {
      max-width: 100%;
    }
    &__right {
      height: 400px;
      width: 100%;
      position: relative;

      svg {
        width: 25%;
        height: auto;
      }
      // .wellington {
      //   position: absolute;
      //   top: 150px;
      //   left: 5%;
      // }
      // .coventry {
      //   position: absolute;
      //   top: 350px;
      //   right: 0;
      // }
    }
  }
}
