.content {
  padding: 2rem 0;

  &__carousel {
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    // gap: 2rem;
  }
}

.productsSlide {
  min-width: 100%;
  display: flex;
  padding-right: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  min-width: 1128px;
  padding-right: 1rem;
}

.productLeft {
  padding-top: 1.5rem;
  width: 387px;

  &--desc {
    font-size: 31px;
    line-height: 37.2px;
    letter-spacing: -0.5px;
    margin: 1.5rem 0;
    color: var(--neutral-100);
  }
  &--paragraph {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    margin-bottom: 1.5rem;
  }
  &--link {
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.25px;
    transition: 0.3s all ease;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 1rem;
    align-items: flex-end;
    &:hover {
      gap: 12px;
    }
  }
}

.productRight {
  // margin-right: -1rem;
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 0rem 0 4rem;
    background-color: white;
  }
  .content {
    &__carousel {

    }
  }

  .productsSlide {
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    min-width: 100%
  }
  .productLeft {
    width: 100%;
    padding-top: 0;
    &--desc {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.25px;
    }
  }
  .productRight {
    margin-right: 0;
    
    svg {
      width: 100%;
      height: auto;
    }
  }
}
