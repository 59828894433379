.bg {
  margin-top: -10rem;
  padding-bottom: 2rem;
}
.contentTop {
  &__bigHeader {
    font-weight: 700;
    line-height: 44.85px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    max-width: 460px;
    font-size: 37px;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
  }
  &__paragraph {
    max-width: 500px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
  }
  &__ttl {
    color: var(--neutral-100);
    margin: 3rem 0 1.7rem;
    font-size: 22px;
    font-weight: 700;
  }
}
.countries {
  overflow-x: auto;

  svg {
    width: 100%;
    height: auto;
    min-width: 1000px;
  }
}
@media only screen and (max-width: 800px) {
  .bg {
    margin-top: -13rem;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
  }
}
