.bg {
  min-height: 510px;
  padding: 4rem 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__heading {
    max-width: 365px;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    margin: 0;
    color: var(--neutral-100);
    margin-bottom: 10px;
  }

  &__paragraph {
    max-width: 460px;
    margin-bottom: 1.6rem;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
  }
}
.articleList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 4%;
}
.article {
  display: flex;
  flex-direction: column;

  > svg {
    width: 30px;
    height: 30px;
  }

  &__ttl {
    font-size: 18px;
    color: var(--neutral-100);
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.25px;
    text-align: left;
    margin: 1rem 0 8px;
  }
  &__txt {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin: 0;
    margin-bottom: 24px;
  }

  &__actions {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;

    & > * {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      transition: 0.3s all ease;
      .arrowPurple {
        margin-top: 5px;
        fill: var(--vobb-primary-70);
      }
      .arrowAtlas {
        margin-top: 5px;
        fill: var(--atlas-primary-70);
        transform: rotate(-35deg);
      }
      &:hover {
        gap: 12px;
      }
    }
    &--explore {
      color: var(--vobb-primary-70);
    }
    &--atlas {
      color: var(--atlas-primary-70);
    }
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0 4rem;
  }
  .content {
    gap: 5px;
    &__heading {
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
  }
  .articleList {
    gap: 3rem;
  }
}
