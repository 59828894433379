.contentTop {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  gap: 2rem;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      background-color: var(--vobb-primary-70);
      padding: 14px 1.5rem;
      text-align: center;
      color: var(--white);
      border-radius: 6px;
      font-size: 15px;
    }
  }
  &__bigHeader {
    max-width: 466px;
    font-size: 37px;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
  }
  &__paragraph {
    max-width: 480px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  .contentTop {
    flex-direction: column;
    gap: 1rem;
    &__left {
      align-items: stretch;
    }
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
    &__right {
      height: 454px;
    }
  }
}
