.checkboxWrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
}
.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--neutral-100);
  display: block;
  margin-bottom: 6px;
}
.input {
  outline: none;
  height: 44px;
  padding: 10px 14px;
  border-radius: 6px;
  border: 1px solid var(--neutral-40);
  box-shadow: 0px 1px 2px 0px #1018280d;
  background-color: var(--white);
  width: 100%;
  color: var(--neutral-100);
  font-family: "Inter";
  font-size: 15px;
  accent-color: var(--vobb-primary-70);

  &::placeholder {
    color: #d0d5dd;
    font-size: 15px;
  }
}
.error {
  font-size: 12px;
  color: var(--error);
  display: block;
  margin-top: 8px;
}
