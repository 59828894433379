.bg {
  background-image: url("../../assets/vectors/illustrations/circles.svg");
  background-repeat: no-repeat;
  background-position: 50% -50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  padding: 9.25rem 0 3rem;
  background-size: 700px;
  background-position: top center;
}

.content {
  // margin: 4.5rem 0;
  max-width: 360px;
  &__done {
    text-align: center;
    color: var(--neutral-100);

    > svg {
      width: 36px;
      height: 36px;
      margin-bottom: 1rem;
      margin-top: 6px;
    }

    h1 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }
    button {
      width: 100%;
      margin-top: 1rem;
    }
  }
  &__newtoVobb {
    position: relative;
    background-color: #fff;
    margin-top: 3rem;
    border-radius: 16px;
    padding: 1rem;
    box-shadow: 0px 12px 16px -4px #1018281a;
    .pattern {
      position: absolute;
      top: 0;
      right: 0;
    }
    .span {
      color: var(--vobb-primary-70);
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 8px;
      display: block;
    }
    h2 {
      color: var(--neutral-100);
      margin-bottom: 1.2rem;
      font-size: 22px;
    }
    p {
      margin-bottom: 1rem;
    }
    a {
      background-color: var(--vobb-primary-70);
      font-weight: 600;
      font-size: 14px;
      color: var(--white);
      border-radius: 6px;
      text-align: center;
      display: block;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .icon {
        fill: var(--white);
      }
    }
  }
}
