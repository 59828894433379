.layout {
  display: grid;
  grid-template-columns: 1.5fr 2.5fr 1fr;
  height: 100dvh;
  max-width: 1400px;
  margin: auto;
  max-height: 1000px;
  overflow: hidden;

  > section {
    // min-height: 800px;
    overflow: auto;
    background-color: #fff;
    background-image: url(../../assets/vectors/illustrations/circles.svg);
    background-repeat: no-repeat;
    padding: 8.8rem 0 3rem;
    background-size: 700px;
    background-position: top center;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
}
.layoutBg {
  background: #f9fafb;
  // height: 100vh;
}

@media screen and (max-width: 900px) {
  .layout {
    grid-template-columns: 1fr;
    max-height: unset;
    height: auto;

    > section {
      background: #fff;
      padding: 2rem 0;
    }
  }
}
