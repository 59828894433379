.bg {
  // padding: 4rem 0;
}

.content {
  &__headline {
    color: var(--neutral-100);
  }
  &__ttl {
    margin: 1rem 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .categories {
      span {
        margin-right: 1rem;
        text-transform: capitalize;
        border: 1px solid var(--neutral-60);
        padding: 0.2rem 0.5rem;
        border-radius: 6px;
        font-weight: 600;
        cursor: pointer;
      }
      .active {
        color: var(--vobb-primary-70);
        border-color: var(--vobb-primary-70);
      }
    }
  }
  &__btm {
    padding: 3rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding-top: 20px;
    justify-content: space-between;
    grid-gap: 50px;
    // & > * {
    //   max-width: 350px;
    // }
  }
  &__pagination {
    display: flex;
    align-items: center;
    font-size: 1rem;
    justify-content: flex-end;
    gap: 1.5rem;

    &__btn {
      background: transparent;
      border: 1px solid var(--neutral-40);
      width: 40px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      svg {
        width: 20px;
      }
    }

    .caretLeft {
      transform: rotate(-90deg);
    }
    .caretRight {
      transform: rotate(90deg);
    }
  }
}

@media only screen and (max-width: 800px) {
  .content__top {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    align-items: stretch;
    padding: 1rem 0 0;
  }
}
