.bg {
  padding: 5rem 0;
}
.contentTop {
  margin-bottom: 3rem;

  &__bigHeader {
    max-width: 596px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    font-size: 37px;
    margin-bottom: 1.5rem;
    line-height: 1.35;
    margin-top: 12px;
  }
  &__paragraph {
    max-width: 574px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    max-width: 550px;
  }
}

.contentBtm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 1.3rem;

  &__item {
    max-width: 590px;

    > svg {
      width: 100%;
      height: auto;
    }

    .title {
      text-transform: uppercase;
      color: var(--vobb-primary-70);
      font-weight: 600;
      letter-spacing: -0.25px;
      text-align: left;
      margin: 1rem 0 6px;
    }
    .heading {
      color: var(--neutral-100);
      font-weight: 800;
      font-weight: 700;
      margin-bottom: 1rem;
      font-size: 18px;
    }
    p {
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
  }

  .contentBtm {
    grid-template-columns: 1fr;

    &__item {
      max-width: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
      
      .image {
        // height: 220px;
      }

      .title {
      }
      .heading {
        margin-bottom: 12px;
      }
      .image {
        svg {
          height: 221px;
        }
      }
    }
  }
}
