.bg {
  padding: 2rem 0 3rem;

  svg {
    width: 100%;
    height: auto;
  }
}
.contentTop {
  margin-bottom: 2.5rem;

  &__bigHeader {
    max-width: 697px;
    font-size: 24px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    line-height: 31.25px;
    letter-spacing: -0.25px;
    text-align: left;
    color: var(--neutral-100);
  }
  &__paragraph {
    max-width: 650px;
    letter-spacing: -0.25px;
    text-align: left;
    line-height: 1.6;

    span {
      font-weight: 600;
      color: var(--vobb-primary-70);
    }
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    // display: none;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
    }
    &__paragraph {
      max-width: 100%;
    }
  }
}
