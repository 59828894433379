.bg {
  padding: 5rem 0 4rem;
}
.content {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  align-items: flex-start;
  margin-top: 2rem;

  &__bigHeader {
    max-width: 450px;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    margin-bottom: 2rem;
  }
  &__badges {
    display: flex;
    gap: 14px 16px;
    max-width: 329px;
    flex-wrap: wrap;

    span {
      padding: 8px 16px;
      border-radius: 93px;
      border: 1px solid var(--vobb-primary-70);
      color: var(--neutral-100);
      font-weight: 600;
    }
  }
  &__right {
    // max-width: 586px;
    &--title {
      color: var(--neutral-100);
      line-height: 1.4;
      max-width: 500px;
      margin-bottom: 5rem;
    }
    &--title2 {
      color: var(--neutral-100);
      line-height: 1.4;
      margin-bottom: 1rem;
      font-size: 18px;
    }
    &--title3 {
      color: var(--neutral-100);
      line-height: 1.4;
      margin-bottom: 0.8rem;
      font-size: 22px;
      font-weight: 800;
    }
    .challenges {
      margin-bottom: 2rem;
    }
    .challenges,
    .results {
      // margin-top: 2rem;
      p {
        line-height: 26px;
        letter-spacing: -0.25px;
        margin-bottom: 10px;

        a {
          color: var(--vobb-primary-70);
          font-weight: 600;
        }
      }
    }
    .stats {
      display: grid;
      grid-template-columns: auto auto;
      gap: 36px 60px;
      margin-top: 3rem;

      p:nth-of-type(2) {
        line-height: 26px;
        letter-spacing: -0.25px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding-top: 3rem;
  }
  .content {
    grid-template-columns: auto;
    gap: 2rem;
    margin-top: 1rem;

    &__badges {
      max-width: unset;
      gap: 10px;

      span {
        font-size: 13px;
        padding: 8px 12px;
      }
    }

    &__right {
      &--title {
        margin-bottom: 2rem;
        font-weight: 400;
        max-width: unset;
        color: var(--neutral-60);
        line-height: 1.6;
        font-size: 14px !important;
      }
    }
    &__bigHeader {
      max-width: unset;
      font-size: 25px;
      margin-bottom: 1rem;
      line-height: 1.4;
    }

    .stats {
      gap: 2rem 1rem;

      & > * {
        width: fit-content;
      }
    }
  }
}
