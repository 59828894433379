.bg {
  min-height: 510px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 4rem;

  &__h2 {
    max-width: 500px;
    margin: 0;
    font-weight: 700;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    font-size: 38px;
    line-height: 1.3;
    margin-bottom: 8px;
  }

  &__btn {
    padding: 12px 24px;
    border-radius: 6px;
    background-color: var(--vobb-primary-70);
    color: var(--white);
    width: fit-content;
  }
}

.article {
  display: flex;
  margin-bottom: 1rem;
  max-width: 1000px;
  gap: 1.5rem;

  p {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 0.5rem;

    strong {
      color: var(--vobb-primary-70);
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0 4rem;
  }
  .content {
    gap: 5px;
    &__h2 {
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
  }
  .article {
    flex-direction: column;
    gap: 0;
  }
}
