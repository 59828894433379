.toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  background-color: var(--vobb-primary-70);
  color: var(--white);
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
  z-index: 1000;
}
