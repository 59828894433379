.bg {
  padding: 5rem 0 2rem;
  background-color: #f5f8ff;
}
.contentTop {
  &__bigHeader {
    max-width: 697px;
    font-size: 35px;
    margin-top: 12px;
    margin-bottom: 1rem;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
  }
  &__paragraph {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    max-width: 700px;
    margin-bottom: 2.5rem;
  }
}

.contentBtm {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  row-gap: 32px;

  &__item {
    max-width: 590px;

    .title {
      color: var(--neutral-100);
      margin: 1.8rem 0 8px;
      font-weight: 600;
    }

    p {
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;
    }
  }
}
.contentLast {
  margin-top: 2rem;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .bg {
    padding: 0 0 2rem;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
  }

  .contentBtm {
    grid-template-columns: auto;
    gap: 3rem;

    &__item {
      max-width: 100%;

      .title {
        margin-top: 0.8rem;
        margin-bottom: 4px;
      }
    }
  }
}
