.bg {
  padding: 5rem 0 3.5rem;
}
.contentTop {
  &__bigHeader {
    max-width: 580px;
    font-size: 38px;
    margin-top: 1rem;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    margin-bottom: 1.2rem;
  }
  &__paragraph {
    letter-spacing: -0.25px;
    text-align: left;
    max-width: 550px;
    margin-bottom: 3rem;
    line-height: 1.6;
  }
}

.contentBtm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem 2rem;


  &__item {
    // max-width: 590px;

    .image {
      width: 100%;
      // border: 1px solid var(--vobb-sec-0);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: auto;
    }

    & > * {
      max-width: 100%;
    }
    .imageMobile {
      display: none;
    }
    .image.adjust {
      margin-top: -5px;
    }
    .title {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.25px;
      text-align: left;
      margin: 1rem 0 8px;
    }
    .heading {
      color: var(--neutral-100);
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 14px;
    }
    p {
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0;
  }
  .contentTop {
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
  }

  .contentBtm {
    grid-template-columns: auto;

    &__item {
      max-width: 100%;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 2rem;
      p:last-of-type {
        margin-bottom: 2rem;
      }

      .image {
        border-radius: 12px;
      }
      .imageMobile {
        display: block;
      }
      .title {
        // text-transform: capitalize;
        // margin: 0.5rem 0;
      }
      .heading {
        // margin: 0;
      }
      .image {
        svg,
        img {
          width: 100%;
        }
      }
    }
  }
}
