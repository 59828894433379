.bg {
  padding: 5rem 0 18rem;
  background-image: url(../../../assets/vectors/illustrations/education/hero-bg.svg);
  background-repeat: no-repeat;
  background-position: top right;
}

.contentTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  max-width: 1046px;
  margin: 5rem auto 0;

  &__bigHeader {
    max-width: 100%;
    font-size: 58px;
    font-weight: 700;
    line-height: 1.35;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 8px;

    span {
      color: var(--vobb-primary-70);
    }
  }
  &__paragraph {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: center;
    max-width: 550px;
    margin-bottom: 1rem;
  }
  &__btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    & > * {
      padding: 12px 24px;
      border-radius: 6px;
      font-weight: 600;
      border: 1px solid var(--vobb-primary-70);
      text-align: center;
    }
    &--earlyAccess {
      color: var(--white);
      background-color: var(--vobb-primary-70);
    }
  }
}

.contentMobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  .contentTop {
    align-items: flex-start;
    margin-top: 2rem;

    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 12px;
    }
    &__paragraph {
      max-width: 100%;

      text-align: left;
    }
    &__btns {
      flex-direction: column;
      width: 100%;
      gap: 1rem;
      & > * {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .contentMobile {
    display: block;
    background-image: url(../../../assets/vectors/illustrations/education/mobileBg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 3rem;
    svg {
      width: 100vw;
      height: auto;
      margin-left: -1rem;
    }
  }
}
