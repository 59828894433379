.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--neutral-100);
  display: block;
  margin-bottom: 6px;
}
.error {
  font-size: 12px;
  color: var(--error);
  display: block;
  margin-top: 8px;
}
