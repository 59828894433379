.wrapper {
  width: 100%;
  position: relative;

  svg {
    width: 6%;
    height: auto;
    min-width: 24px;
  }
}
.globe {
  width: 100%;
  height: auto;
  width: calc(100% + 4.5rem);
  margin-left: 2.7rem;
}
.marker1 {
  position: absolute;
  top: 0;
  left: 50%;
  top: 0%;
  transform: scale(0);
  animation: animate1 6s ease-in-out 0s infinite;

  path {
    fill: #250c89;
  }
}
.marker2 {
  position: absolute;
  right: 0;
  top: 7%;
  transform: scale(0);
  animation: animate2 6s ease-in-out infinite;

  path {
    fill: var(--vobb-primary-70);
  }
}
.marker3 {
  position: absolute;
  right: -15%;
  top: 30%;
  transform: scale(0);
  animation: animate3 6s ease-in-out infinite;

  path {
    fill: #006ec9;
  }
}
.marker4 {
  position: absolute;
  right: 40%;
  top: 40%;
  transform: scale(0);
  animation: animate4 6s ease-in-out infinite;

  path {
    fill: var(--atlas-primary-70);
  }
}
.marker5 {
  position: absolute;
  right: 19%;
  top: 62%;
  transform: scale(0);
  animation: animate5 6s ease-in-out infinite;

  path {
    fill: #630236;
  }
}

@keyframes animate1 {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animate2 {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animate3 {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0);
  }
  40% {
    transform: scale(0);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animate4 {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0);
  }
  40% {
    transform: scale(0);
  }
  60% {
    transform: scale(0);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animate5 {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0);
  }
  40% {
    transform: scale(0);
  }
  60% {
    transform: scale(0);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: var(--max-width)) {
  .globe {
    margin-left: 1rem;
    width: 100%;
  }
  .marker1 {
    left: 40%;
  }
  .marker2 {
    right: 13%;
  }
  .marker3 {
    right: 0;
  }
  .marker4 {
    right: 46%;
    top: 46%;
  }
  .marker5 {
    right: 30%;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    position: absolute;
    right: -5rem;
    z-index: -1;
    width: 100%;
    min-height: 500px;
    top: 0;
    right: -40%;
    max-width: 500px;

    .marker2,
    .marker3,
    .marker4 {
      display: none;
    }
    .marker5 {
      left: 24%;
      top: 61%;
      right: unset;
    }
    .marker1,
    .marker5 {
      //   animation: animate 3s ease-in-out infinite;
    }

    @keyframes animate1 {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }

    @keyframes animate5 {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .globe {
    margin-left: 0;
    opacity: 0.5;
  }
}
