.portalWrap {
  background-color: white;
  position: absolute;
  top: 105%;
  left: 5vw;
  border: 1px solid var(--vobb-primary-70);
  padding: 2rem;
  border-radius: 1rem;
  max-width: 995px;

  @media only screen and (max-width: 1024px) {
    max-width: 100vw;
  }

  &__header {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 3.5rem;
    margin-bottom: 1.5rem;
    span {
      white-space: nowrap;
      padding: 0 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      color: var(--neutral-60);
    }
  }

  &__products {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 3.5rem;
    row-gap: 1rem;
  }
}
