.bg {
  margin-top: -10rem;
  padding-bottom: 2rem;
}
.contentBtm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  

  &__bigHeader {
    margin-top: 1rem;
    line-height: 1.35;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--neutral-100);
    max-width: 400px;
    font-size: 37px;
    margin-bottom: 1.3rem;
  }
  &__paragraph {
    max-width: 460px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 1.6rem;
  }
  &__checks {
    &--item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 6px;

      span {
        line-height: 26px;
        letter-spacing: -0.25px;
      }
    }
  }
  &__right {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    margin-top: -13rem;
  }
  .contentBtm {
    gap: 1rem;
    grid-template-columns: 1fr;

    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
    &__paragraph {
      max-width: 100%;
    }
    &__checks {
      &--item {
        align-items: flex-start;
      }
    }
    &__right {
      margin-top: 1.5rem;
    }
  }
}
