.card {
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  .coverImg {
    max-width: 100%;
    height: 250px;
    transition: all 0.3s ease;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;

  }
  &:hover {
    .coverImg {
      transform: scale(1.05);
    }
   
  }
  .topic {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: var(--vobb-primary-70);
    margin: 1rem 0;
  }
  .title {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 1rem;
    text-transform: capitalize;
    color: var(--neutral-100);
  }
  .content {
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  .author {
    display: flex;
    align-items: center;
    margin-top: auto;
    gap: 12px;

    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid var(--neutral-20);
    }

    &__name {
      font-weight: 600;
      color: var(--neutral-100);
      margin-bottom: 4px;
    }

    &__role {
      font-size: 13px;
      color: var(--neutral-70);
    }
  }
}
