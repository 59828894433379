.bg {
  background-color: #f9fafb;
  height: 100dvh;
  overflow: auto;
}

.content {
  padding: 2rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &__logo {
    margin-bottom: 2rem;
    display: block;
    width: fit-content;

    svg {
      min-width: 100px;
      min-height: 32px;
    }
  }
  &__step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-bottom: 2rem;
    .completedPersonal {
      fill: #d3dbff;
      path:nth-of-type(1) {
        stroke: transparent;
      }
      path:nth-of-type(2) {
        stroke: #3d58d9;
      }
    }
    .completedCompany {
      fill: #f4ebff;
      path:nth-of-type(1) {
        stroke: transparent;
      }
      path:nth-of-type(2) {
        stroke: #7f56d9;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;
      p {
        font-weight: 600;
        margin: 0;
      }
      &.active {
        color: var(--neutral-100);
      }
    }
  }

  &__btm {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    a {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

@media screen and (max-width: 900px) {
  .bg {
    height: auto;
    background: #fff;
  }
  .activeStep {
    border-bottom: 2px solid var(--vobb-primary-70);
  }
  .content {
    &__stepWrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;
      overflow: auto;
      width: 100%;
      white-space: nowrap;
    }

    &__step {
      margin-bottom: 0;
      padding-bottom: 6px;
      gap: 8px;

      svg {
        width: 36px;
        height: 36px;
        // display: none;
      }
    }
    &__btm {
      display: none;
    }
  }
  .details {
    text-align: center;
    width: 100%;
    span {
      display: none;
    }
  }
}
