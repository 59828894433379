.bg {
  // background-image: url("../../assets/vectors/illustrations/circles.svg");
  // background-repeat: no-repeat;
  // background-position: 50% -22%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

.content {
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
}

.goBack {
  display: block;
  width: 28px;
  height: 28px;
  transform: rotate(180deg);
  border: 1px solid var(--neutral-60);
  border-radius: 50%;
  padding: 4px;

  path {
    fill: var(--neutral-60);
  }
}

.details {
  color: var(--neutral-100);

  h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    margin: 2rem 0 0;
    text-align: center;
  }
  p {
    margin: 12px 0 2.5rem;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
}

.form {
  &__flex {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    > div {
      width: 48%;
    }
  }
}
.formSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;
  .textarea {
    height: 154px;
    padding-bottom: 7.5rem;
  }
  .subscribe {
    .checkbox {
      height: 16px;
      width: 16px;
    }
  }
}
.btnWrap {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  &__btn {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--neutral-20);
    cursor: pointer;
    &--active {
      background-color: var(--vobb-primary-70);
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    max-width: unset;
    padding: 0 1rem;

    > svg {
      display: none;
    }
    .goBack {
      display: block;
      margin-bottom: 1.2rem;
    }
  }
  .details {
    margin-bottom: 1.5rem;
    display: none;

    h2 {
      display: none;
    }
    p {
      text-align: left;
      margin: 0;
    }
  }
}
