.btnWrap {
  display: none;
}

@media only screen and (max-width: 800px) {
  .btnWrap {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    &__btn {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--neutral-20);
      cursor: pointer;
      &--active {
        background-color: var(--vobb-primary-70);
      }
    }
  }
}
