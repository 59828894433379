.bg {
  background-color: var(--neutral-10);
  display: flex;
  align-items: center;
  text-align: left;
  gap: 2rem;
  padding: 1.5rem;
  height: 100dvh;

  & > * {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    h4 {
      font-weight: 600;
    }
    p {
    }
  }
}

@media screen and (max-width: 900px) {
  .bg {
    height: auto;
    grid-row-start: 2;
    padding: 0 1rem 0.5rem;
    background: #fff;

    h4 {
      display: none;
    }

    p {
      // font-size: 12px;
      line-height: 1.5;
    }
  }
}