.btn {
  border: 1px solid;
  outline: none;
  font-size: 14px;
  border-radius: 6px;
  padding: 14px 1.5rem;

  &--fill-blue {
    color: var(--white);
    background: var(--vobb-primary-70);
    border-color: var(--vobb-primary-70);
  }

  &--fill-white {
    color: var(--vobb-primary-70);
    background: var(--white);
    border-color: var(--white);
  }

  &--outline-blue {
    background: transparent;
    color: var(--vobb-primary-70);
    border-color: var(--vobb-primary-70);
  }

  &--transparent {
    background: transparent;
    color: var(--vobb-primary-70);
    border-color: var(--transparent);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
