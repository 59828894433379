.aboutBg {
  padding: 5rem 0;
  position: relative;
}
.about {
  &__top {
    display: flex;
    padding-top: 2rem;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    &--right {
      max-width: 540px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;
      margin: 1.6rem 0;
    }
  }

  &__bigHeader {
    font-size: 38px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.5px;
    text-align: left;
    margin-top: 1rem;
    width: 517px;
    max-width: 100%;
    color: var(--neutral-100);
    strong {
      color: var(--vobb-primary-70);
    }
    margin-bottom: 1rem;
  }
  &__btm {
    display: grid;
    gap: 2rem;
    grid-template-columns: auto auto;
    margin-top: 3rem;
    .item {
      max-width: 264px;
      &__title {
        font-weight: 600;
        margin-bottom: 1rem;
        color: var(--vobb-primary-70);
      }
      &__subTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.25px;
        text-align: left;

        color: var(--neutral-100);
      }
      p {
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.25px;
        text-align: left;
        strong,
        a {
          font-weight: 400;
          color: var(--vobb-primary-70);
        }
      }
    }
    &--left,
    &--right {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
}
@media only screen and (max-width: 800px) {
  .aboutBg {
    padding: 0;
  }
  .about {
    &__top {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      &--right {
        max-width: 100%;
        padding-left: 0;
        margin-top: 0.2rem;
      }
    }
    &__bigHeader {
      font-size: 25px;
      font-weight: 700;
      line-height: 31px;
      letter-spacing: -0.25px;
      text-align: left;
      height: auto;
      width: 100%;
    }
    &__btm {
      .item {
        margin-bottom: 1rem;
      }
      &--left,
      &--right {
        display: grid;
        grid-template-columns: auto;
      }
    }
  }
}
