.bg {
  position: relative;
  // min-height: 894px;
  padding-top: 4rem;
  padding-bottom: 8rem;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__left {
    .txt {
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;
      max-width: 480px;
      line-height: 1.5;
      margin-bottom: 12px;
    }
  }

  &__desc {
    font-size: 36px;
    margin: 1rem 0;
    color: var(--neutral-100);
    letter-spacing: -0.25px;
    max-width: 500px;
  }

  &__right {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding-top: 3rem;
    min-height: 600px;
  }
  .content {
    grid-template-columns: 1fr;

    &__left {
      margin-top: 0;
      position: relative;
      z-index: 1;

      .txt {
        // color: var(--neutral-100);
      }
    }

    &__desc {
      font-size: 25px;
      font-weight: 700;
      letter-spacing: -0.25px;
    }

    &__right {
      width: 90vw;
      top: -6rem;
      right: 0;
    }
  }
}
