.bg {
  min-height: 510px;
  padding: 4rem 0;
}

.content {
  &__heading {
    max-width: 709px;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -0.5px;
    text-align: left;
    margin: 0;
    color: var(--neutral-100);
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }

  &__paragraph {
    max-width: 846px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 3.2rem;
  }
}
.articleList {
  display: flex;
  justify-content: space-between;
}
.articleList.desktop {
  gap: 1rem;
}
.articleList.mobile {
  display: none;
}

.reason {
  max-width: 252px;
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);

  &__ttl {
    color: var(--neutral-100);
    font-weight: 600;
    text-align: left;
    margin: 2rem 0 0.6rem;
  }

  &__txt {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    margin: 0;
    line-height: 1.6;
  }
}

@media only screen and (max-width: 800px) {
  .bg {
    padding: 2rem 0 4rem;
  }
  .content {
    gap: 5px;

    &__heading {
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
    }
  }
  .articleList.mobile {
    display: flex;
    margin: 2rem 0 4rem;
    overflow-x: auto;
  }
  .articleList.desktop {
    display: none;
  }
  .reason {
    max-width: unset;
    width: 100%;
    min-width: 100%;

    &__txt {
      max-width: 400px;
    }
  }
}
