.bg {
  padding: 5rem 0 3rem;
}
.contentTop {

  &__bigHeader {
    margin-top: 1rem;
    letter-spacing: -0.5px;
    color: var(--neutral-100);
    max-width: 600px;
    font-size: 37px;
    margin-bottom: 1.5rem;
    line-height: 1.35;
  }
  &__paragraph {
    max-width: 560px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.25px;
    text-align: left;
    strong {
      color: var(--vobb-primary-70);
    }
  }
}
.contentBtm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 3rem;

  img {
    width: 100%;
    margin-top: 2rem;
  }

  &__left,
  &__right {
    .ttl {
      color: var(--neutral-100);
      max-width: 488px;
      font-size: 24px;
      font-weight: 700;
      line-height: 31.25px;
      letter-spacing: -0.25px;
      text-align: left;
      margin-bottom: 1rem;
    }
    .paragraph {
      max-width: 380px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.25px;
      text-align: left;
    }
    .checks {
      margin-top: 1rem;
      &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        margin-bottom: 6px;

        svg {
          rect {
            fill: #effffe;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .bg {
    padding: 3rem 0 2rem;
  }
  .contentTop {
    align-items: flex-start;
    &__bigHeader {
      max-width: 100%;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
      text-align: left;
    }
    &__paragraph {
      max-width: 100%;
      text-align: left;
    }
  }
  .contentBtm {
    grid-template-columns: auto;
    margin-top: 2rem;
   
  }
}
