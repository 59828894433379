.footer {
  margin-top: 10rem;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
}

.content {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
  max-width: 850px;
}

.linkWrap {
  p:first-of-type {
    margin-top: 0;
    font-size: 15px;
    color: var(--neutral-100);
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 12px;
    transition: 0.3s all ease;
    .arrowAtlas {
      margin-bottom: -2px;
      fill: var(--atlas-primary-70);
      font-size: 1rem;
    }

    &.liveItem:hover {
      gap: 1.5rem;
    }

    span:nth-child(1) {
      min-width: 70px;
    }

    &--live {
      color: var(--neutral-100);
    }

    &--comingSoon {
      color: var(--vobb-primary-70);
      background-color: #e1dafc;
      border-radius: 47px;
      padding: 4px 8px;
      font-size: 11px;
      font-weight: 400;
    }
  }
}

.footnote {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid var(--neutral-10);
  margin: 2rem 0;

  &__left {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    margin-bottom: 1rem;

    &--item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    margin-top: 3rem;
  }
  .section {
    gap: 2rem;
  }
  .section,
  .footnote {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
  .content {
    gap: 2rem;
    width: 100%;
    justify-content: space-between;
    .linkWrap {
      &__item {
        font-size: 14px;
        &--comingSoon {
          transform: scale(0.95);
        }
      }
    }

    .contact {
      padding-right: 1rem;
    }
  }
}

@media screen and (max-width: 700px) {
  .content {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
