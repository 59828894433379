.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 1.5rem;
  width: 100%;

  .input {
    width: 100%;
    padding: 10px;
    padding-left: 60px;
    border: 1px solid #dae0e6;
    box-shadow: unset;
    border-radius: 6px;
    color: var(--neutral-100);
    font-family: "Inter";
    font-size: 15px;

    &:focus {
      border-color: #dae0e6;
      box-shadow: unset;
    }
  }
}
.label {
  font-size: 14px;
  background: transparent;
  padding: 0;
  color: var(--neutral-100);
  font-weight: 500;
  margin-bottom: 8px;
}
.message {
  font-size: 12px;
  color: var(--error);
  display: block;
  margin-top: 8px;
}

.req {
  color: var(--error);
  font-weight: 600;
}

.hasValue {
  background: #fbfbff;
}
