.bg {
  // margin: 4rem 0;
  padding-top: 120px;
  padding-bottom: 4rem;
  border-bottom: 1px solid var(--neutral-30);
  margin-bottom: 4rem;
}

.content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  grid-gap: 2rem;

  .cover {
    max-width: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  aside {
    max-width: 100%;
    .paragraph {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      color: var(--vobb-primary-70);
      margin: 1rem 0;
    }
    .heading {
      color: var(--neutral-100);
      font-size: 30px;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: -0.5px;
      margin: 1rem 0;

      &:hover {
        color: var(--vobb-primary-70);
      }
    }
    .body {
      line-height: 1.5;
      margin-bottom: 2rem;
    }
    .author {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      gap: 12px;

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid var(--neutral-20);
      }

      &__name {
        font-weight: 600;
        color: var(--neutral-100);
        margin-bottom: 4px;
      }

      &__role {
        font-size: 13px;
        color: var(--neutral-70);
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .content {
    grid-template-columns: 1fr;
    & > * {
      max-width: 100%;
    }
    aside {
      width: 100%;
      .heading {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
}
