.navWrap {
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  transition: 0.3s;
  z-index: 2;

  &--white {
    background: var(--white);
    box-shadow: 0 1px 6px 1px rgb(74 34 235 / 12%);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;

  &--white {
    .nav {
      .navItem {
        color: var(--neutral-100);

        &--active {
          color: var(--vobb-primary-70);
        }
      }
    }
  }

  &__btn {
    padding: 8px 22px;
    background-color: var(--vobb-primary-70);
    color: var(--white);
    border-radius: 5px;
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  &__mobile {
    cursor: pointer;
    display: none;

    &--body {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: var(--white);
      width: 100%;
      min-height: 100dvh;

      .content {
        box-shadow: 0px 20px 24px -4px #1018281a;
        padding: 1rem;
        border-radius: 1rem;
        margin: 0 1rem;
        height: 90dvh;
        position: relative;

        &__headers {
          display: grid;
          grid-template-columns: auto auto auto;
          gap: 38.5px;
          align-items: center;
          max-width: 100%;
          &--active {
            border-bottom: 1px solid var(--vobb-primary-70);
            color: var(--vobb-primary-70) !important;
          }
          margin-bottom: 1rem;
          p {
            padding: 8px;
            font-weight: 600;
            color: var(--neutral-100);
            font-size: 1rem;
            text-align: center;
            text-transform: capitalize;
          }
          @media only screen and (max-width: 800px) {
            gap: 0;
            justify-content: space-between;
            p {
              font-size: 14px;
            }
          }
        }
        &__item {
          overflow-y: scroll;
          height: 60dvh;
        }
        &__bottom {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #f5f8ff;
          display: flex;
          justify-content: center;
          align-items: center;

          &--btn {
            color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;
            width: 228px;
            border-radius: 56px;
            margin: 28px 0;
            font-size: 1rem;
            height: 40px;
            background-color: var(--vobb-primary-70);
          }
        }
      }
    }
    @media only screen and (max-width: 800px) {
      display: block;
    }
  }
}
.nav {
  display: flex;
  align-items: center;
  gap: 3rem;

  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.navItem {
  color: var(--white);
  font-size: 14px;
  border: none;
  background: transparent;
  padding: 0;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
}
