.section {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__heading {
    text-align: center;
    margin: 1rem auto 7rem;
    font-weight: 700;
    color: var(--neutral-100);
    font-size: 39px;
  }
  &__btm {
    display: flex;
    justify-content: space-around;
    margin: 2rem 0;
    &--item {
      min-width: 264px;
      display: flex;
      flex-direction: column;
      .ttl {
        color: var(--neutral-100);
        margin-bottom: 12px;
      }
      .paragraph {
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.25px;
        text-align: center;
        margin-bottom: 8px;
      }
      a {
        text-decoration: underline !important;
        font-weight: 600;
        color: var(--vobb-primary-70);
      }
    }
  }
}
.worldImg {
  max-width: 100%;
}
@media only screen and (max-width: 800px) {
  .section {
    text-align: left;
    padding-top: 4rem;
    &__heading {
      text-align: left;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.25px;
      margin-bottom: 3rem;
    }
    &__btm {
      flex-direction: column;
      &--item {
        margin-bottom: 2rem;
        .paragraph {
          text-align: left;
        }
      }
    }
  }
}
